import React, { useEffect, useState } from "react";
import { getLast100Puzzles } from "../integrations/APIClient.js"; // Adjust the import path as necessary
import MultiAxisLineChart from "./MultiAxisLineChart"; // Adjust the import path as necessary

const PuzzleRatingsTimeSeries = ({ userId }) => {
  const [puzzleData, setPuzzleData] = useState({
    dates: [],
    ratings: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!userId) {
      setError("User ID is required");
      setLoading(false);
      return;
    }

    getLast100Puzzles(userId)
      .then((data) => {
        // Transform the data into the format expected by MultiAxisLineChart
        const transformedData = data.reduce(
          (acc, puzzle) => {
            // Assuming 'timestamp' is ISO string and 'puzzleRating' is the rating
            acc.dates.push(puzzle.timestamp.split("T")[0]); // Just get the date part
            acc.ratings.push(puzzle.puzzleRating);
            return acc;
          },
          { dates: [], ratings: [] }
        );

        setPuzzleData(transformedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching last 100 puzzles:", error);
        setError(error.toString());
        setLoading(false);
      });
  }, [userId]);

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <MultiAxisLineChart
          dates={puzzleData.dates}
          ratings={puzzleData.ratings}
        />
      )}
    </div>
  );
};

export default PuzzleRatingsTimeSeries;
