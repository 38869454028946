import React, { useState, useEffect } from "react";
import { getTotalUserHints } from "../integrations/APIClient"; // Adjust the import path as necessary

const UserHints = () => {
  const [hintsCount, setHintsCount] = useState(null); // Store the hints count
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getTotalUserHints()
      .then((data) => {
        setHintsCount(data.totalHintsCount); // Update state with the fetched hints count
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, []); // Dependency array left empty as we only want to fetch the data once

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <span>
      {hintsCount !== null ? (
        <h2>
          {hintsCount} <br></br>
          <img
            src="/hint1.webp"
            alt="Descriptive text"
            className=" medal img-fluid iconess "
          />
        </h2> // Display the total hints count
      ) : (
        <span>No hints used.</span>
      )}
    </span>
  );
};

export default UserHints;
