import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Chessground from "@react-chess/chessground";
import { Chess, SQUARES } from "chess.js";
import UserFailures from "./UserFailures";
import json from "json-stringify-safe";
import CustomAlert from "../components/CustomAlert"; // Adjust the import path as needed
import ChessMoveIndicator from "../components/ChessMoveIndicator"; // Import the new component
import PuzzlesByCategory from "./PuzzlesByCategory"; // Adjust the import path as necessary

import {
  getUser,
  getPuzzle,
  getFirstPuzzle,
  checkPuzzleResult,
  getUserFailures,
} from "../integrations/APIClient";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";

import {
  showSuccess,
  showError,
  showHintMessage,
} from "../components/SuccessFailMessage";
import UserSuccesses from "./UserSuccesses";
import InfoPuzzle from "./InfoPuzzle";
import UserHints from "./UserHints";
import useAlert from "./useAlert"; // Ensure this path is correctly pointing to your useAlert hook.
import { Alert } from "react-bootstrap";

// Play.js or any other file
import React from "react";
import { toDests, toColor, toPgnHistory, game } from "../utils/chessUtils"; // Adjust the path as needed
import Promotion from "../components/PromotionComponent"; // Adjust the path as needed

// Usage within the component

export default function Play() {
  const [category, setCategory] = useState("basic"); // Example category
  const [isHintClicked, setIsHintClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const puzzleID = useParams().id;
  const puzzle = useRef(null);
  const [gameFen, setGameFen] = useState("8/8/8/8/8/8/8/8");
  const [puzzleFinished, setPuzzleFinished] = useState(false);
  const moveNr = useRef(0);
  const [config, setConfig] = useState({});
  const [showHint, setShowHint] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    variant: "",
    message: "",
    success: "",
  });
  //const user = useRef(null);
  const [user, setUser] = useState({
    rating: "Loading...",
    points: "Loading...",
    category_rating: {},
  });

  const showAlert = (message, variant) => {
    setAlertInfo({ show: true, variant, message, success });
  };

  // Function to hide the alert
  const hideAlert = () => {
    setAlertInfo({ ...alertInfo, show: false });
  };

  const [promoting, setPromoting] = useState(false);

  function checkPromotion(game, orig, dest) {
    const pgn = orig.concat(dest);
    if (!(game.get(orig).type === "p")) return pgn;
    if (
      (game.get(orig).color === "w" && orig[1] == 7 && dest[1] == 8) ||
      (game.get(orig).color === "b" && orig[1] == 2 && dest[1] == 1)
    ) {
      setPromoting(pgn);
      return false;
    }
    return pgn;
  }

  function promotion(pgn) {
    setPromoting(false);
    makeUserMove(pgn);
  }

  function updateGame(fen) {
    const newConfig = {
      fen: fen,
      orientation:
        puzzle.current.fenPosition.split(" ")[1] === "w" ? "black" : "white",
      turnColor: toColor(game),
      movable: {
        color: toColor(game),
        dests: toDests(game),
        events: {
          after: userMove,
        },
      },
    };

    setConfig(newConfig);
  }
  useEffect(() => {
    if (puzzleID) {
      getUserFailures("crushing")
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
      getPuzzle(puzzleID).then((data) => {
        puzzle.current = data;
        puzzle.current.after_pgn = puzzle.current.after_pgn.split(" ");
        setGameFen(puzzle.current.fenPosition);
        game.load(puzzle.current.fenPosition);
        setConfig({ orientation: game.turn() === "b" ? "white" : "black" });
        updateGame(puzzle.current.fenPosition);
        computerMove(true);
      });
    } else {
      getFirstPuzzle().then((data) => {
        window.history.pushState("", "", "/play/" + data["_id"]["$oid"]);
        puzzle.current = data;
        puzzle.current.after_pgn = puzzle.current.after_pgn.split(" ");
        setGameFen(puzzle.current.fenPosition);
        game.load(puzzle.current.fenPosition);
        setConfig({ orientation: game.turn() === "b" ? "white" : "black" });
        updateGame(puzzle.current.fenPosition);
        computerMove(true);
        // window.window.location.href = "/Play/"+data["id"]["$oid"];ff
      });
    }
  }, []);

  function makeUserMove(pgn, hint = false) {
    console.log(puzzle.current["_id"]["$oid"], toPgnHistory(game));
    console.log("hint");

    if (hint) {
      setIsHintClicked(true);

      // Assuming 'hint' is a boolean indicating a hint request
      checkPuzzleResult(puzzle.current["_id"]["$oid"], toPgnHistory(game), true)
        .then((result) => {
          // Assuming 'result' is a JSON string containing the hint information
          setAlertInfo({
            show: true,
            variant: "warning ",
            user: result.user,
            addedOrRestRating: result.addedOrRestRating,
            puzzleSuccessMessage: "Here's a hint for you!",
            success: "hint",
            fen: puzzle.current.fenPosition,
            after_pgn: puzzle.current.after_pgn,
            config: config,
          });
        })
        .catch((error) => {
          showError(
            error.message || "An error occurred while fetching the hint."
          );
        });
      setPuzzleFinished(true);
      return; // Optionally return early if you don't want to proceed further after showing a hint
    }

    if (pgn === puzzle.current.after_pgn[moveNr.current]) {
      game.move(pgn);

      if (moveNr.current == puzzle.current.after_pgn.length - 1) {
        checkPuzzleResult(puzzle.current["_id"]["$oid"], toPgnHistory(game))
          .then((result) => {
            // const resultObject = JSON.parse(result); // Assuming result needs parsing
            setAlertInfo({
              show: true,
              variant: "success",
              user: result.user,
              addedOrRestRating: result.addedOrRestRating,
              puzzleSuccessMessage: puzzle.current.success_message,
              success: "true",
            });
          })
          .catch((error) => {
            // Update state to show error message
            setAlertInfo({
              show: true,
              variant: "success",
              user: result.user,
              addedOrRestRating: result.addedOrRestRating,
              puzzleSuccessMessage: puzzle.current.success_message,
              success: "error",
            });
          });
        updateGame(game.fen());
        setPuzzleFinished(true);
      } else {
        moveNr.current++;
        computerMove();
      }
    } else {
      // This part remains unchanged, as it handles incorrect moves or puzzle failures
      checkPuzzleResult(puzzle.current["_id"]["$oid"], toPgnHistory(game))
        .then((result) => {
          setAlertInfo({
            show: true,
            variant: "error",
            user: result.user,
            addedOrRestRating: result.addedOrRestRating,
            puzzleSuccessMessage: puzzle.current.fail_message,
            success: "false",
          });
        })
        .catch((error) => {
          showError(error);
        });

      setPuzzleFinished(true);
      updateGame(game.fen());
    }
  }

  function repeat() {
    window.location.reload(false);
  }
  function userMove(orig, dest, metadata) {
    const pgn = checkPromotion(game, orig, dest);
    if (!pgn) return;
    makeUserMove(pgn);
  }
  function computerMove(firstLoad = false) {
    const move = puzzle.current.after_pgn[firstLoad ? 0 : moveNr.current];

    if (firstLoad) {
      moveNr.current = 1;
    } else {
      moveNr.current++;
    }
    game.move(move);
    updateGame(game.fen());
  }

  function nextPuzzle() {
    window.location.replace("/play");
  }
  function hint() {
    setShowHint(!showHint);
    makeUserMove(moveNr.current, true);
  }

  useEffect(() => {
    // Presumably you have a function to fetch user data
    // For demonstration, I'm using a mock function getUserData
    const fetchUserData = async () => {
      try {
        const userData = await getUser(); // Use the actual function to fetch user data
        setUser(userData); // Update user state with the fetched data
      } catch (error) {
        // Handle error case, e.g., by setting user to null or showing an error message
        setUser(null);
      }
    };

    fetchUserData();
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // if (!user) {
  //   setShowModal(true);
  // }
  useEffect(() => {
    // When the component mounts, add a class to the body
    document.body.classList.add("play-active");

    // This return function is a cleanup function that React runs when the component unmounts
    return () => {
      // When the component unmounts, remove the class from the body
      document.body.classList.remove("play-active");
    };
  }, []); // The empty array means this effect runs only once when the component mounts and not on subsequent re-renders

  return (
    <>
      <Row>
        <Col
          xs={12}
          sm={8}
          md={9}
          lg={8}
          xl={8}
          xxl={8}
          className="  ml-5 column3"
        >
          <Promotion pgn={promoting} onPromotion={promotion} />
          <Chessground
            contained={false}
            config={config}
            className=" img-fluid ml-5"
          />
        </Col>
        <Col
          xs={12}
          sm={4}
          md={3}
          lg={4}
          xl={4}
          xxl={4}
          className="infoPuzzle column4 "
        >
          {/* <Button onClick={handleShowModal}>Show Message</Button> */}
          {/* <EncouragingMessageModal
          showModal={showModal}
          onClose={handleCloseModal}
        /> */}
          {/* Conditionally render the Alert if `show` is true */}
          {/* <PuzzlesByCategory category="xRayAttack" /> */}
          {alertInfo.show && (
            <CustomAlert
              variant={alertInfo.variant}
              message={alertInfo.message}
              onClose={hideAlert}
              user={alertInfo.user}
              addedOrRestRating={alertInfo.addedOrRestRating}
              puzzleSuccessMessage={alertInfo.puzzleSuccessMessage}
              success={alertInfo.success}
              fen={alertInfo.fen}
              after_pgn={alertInfo.after_pgn}
            />
          )}
          <Row className="ml-3">
            <Col
              xs={user ? 2 : 12}
              xl={user ? 2 : 12}
              className={user ? "col col2" : "col col1"}
            >
              <UserFailures />
            </Col>
            <Col xs={user ? 3 : 12} xl={user ? 2 : 12} className="col">
              <UserSuccesses />
            </Col>
            <Col xs={user ? 3 : 12} xl={user ? 2 : 12} className="col">
              <UserHints />
            </Col>
          </Row>
          <InfoPuzzle
            puzzle={puzzle}
            user={user}
            config={config}
            nextPuzzle={nextPuzzle}
            puzzleFinished={puzzleFinished}
            repeat={repeat}
            hint={hint}
            isHintClicked={isHintClicked}
            showHint={showHint}
            after_pgn={puzzle.current ? puzzle.current.after_pgn : ""}
            fen={gameFen}
          />{" "}
        </Col>
      </Row>{" "}
    </>
  );
}
