export function validate(user) {
    if (!user) {
        return []
    }
    const unvalidated = []
    if (!user?.firstName) {
        unvalidated.push("firstname")
    }
    // if (!user?.lastName) {
    //     unvalidated.push("lastname")
    // }
    if (!user?.nickName) {
        unvalidated.push("nickname")
    }
    return unvalidated
}